<template>
    <div style="height: 100%; background: #f9f9f9; overflow: auto;">
        <!-- 信息 -->
        <div class="goods-info" v-if="data.order_detail">
            <div class="goods-box">
                <div class="goods-title">
                    {{data.title}}
                </div>

                <div class="flex-item">
                    <div class="small-title">
                        检查内容
                    </div>
                    <div v-html="data.get_goods.check_items" style="font-size:12px">
                    
                    </div>
                </div>
					
                <div class="flex-item code-box" v-if="data.order_detail && data.order_detail.length> 0 && kindStatus == 0 &&order_status!=5 ">
                    <div class="small-title" v-if="data.state != 3">
                        兑换码
                    </div>
                    <div class="code" v-for="item in data.order_detail" :key="item.orders_id">
                        <van-button v-if="data.state == 3" type="info" size="large" round @click="updated">已升级</van-button>
                        <div v-else-if="item.status == 0">{{item.code | omit}}</div>
                        <div v-else>{{item.code}}</div>
                        <van-button round type="info" size="mini" @click="makeAppointment(item.id)" v-if="data.state != 3 && item.status == 0">立即预约</van-button>
                        <van-button round type="warning" size="mini" v-if="data.state != 3 && item.status == 1 && data.third_id == 0">已预约</van-button>
                        <van-button round type="warning" size="mini" v-if="data.state != 3 && item.status == 1 && data.third_id > 0" @click="showThird(item.status,item.id)">立即预约</van-button>
                    </div>
                </div>
            </div>

            <!-- 收货信息 -->
            <div class="goods-box" v-if="data.name != null">
                <div class="flex-item">
                    <div class="small-title">收货地址</div>
                </div>
                <div class="code" style="justify-content:end">
                    <div>{{data.name}}</div>
                    <div style="margin-left:15px">{{data.tel}}</div>
                </div>
                <div class="code">
                   {{data.province}}{{data.city}}{{data.area}}{{data.detail}}
                </div>
            </div>
			
			<div class="goods-box" v-if="data.is_send == 1">
				<div class="flex-item ">
				    <div class="small-title">收货信息</div>
				</div>
				<div class="code" style="justify-content:end">
				    <div style="color: #666;">快递公司</div>
				    <div style="margin-left:15px">{{data.express}}</div>
				</div>
				<div class="code" style="display: block;overflow: hidden;">
				    <div style="float: left;color: #666;">快递单号</div>
				    <div style="margin-left:15px;float: left;">{{data.express_no}}</div>
					<button class="copy" style="float: right;" @click="copyText(data.express_no)">复制</button>
				</div>
				<div class="code" style="justify-content:end">
				    <div style="color: #666;">发货时间</div>
				    <div style="margin-left:15px">{{data.send_at}}</div>
				</div>
			</div>
			
            <div class="goods-box">
                <div class="flex-item">
                    <div class="small-title">
                        使用规则
                    </div>
                    <div v-html="data.get_goods.used_rules" style="font-size:12px">

                    </div>
                </div>
            </div>

            <div class="goods-box"  v-if="data.get_goods.has_course == 1 && order_status != 2 && order_status !=5 && kindStatus == 0">
                <van-cell title="康养课程"  is-link value="免费上课"  @click="hrefCoures"/>
            </div>

            <div class="goods-box">
                <div class="flex-item">
                    <div class="small-title">
                        订单信息
                    </div>
                    <div class="rule">
                        <div class="goods-info-item">
                            <div class="item-title">已购商品</div>
                            <div>{{data.title}}</div>
                        </div>
                        <div class="goods-info-item">
                            <div class="item-title">订单号</div>
                            <div>{{data.sn}}</div>
                        </div>
                        <div class="goods-info-item">
                            <div class="item-title">下单时间</div>
                            <div>{{data.created_at}}</div>
                        </div>
                        <div class="goods-info-item">
                            <div class="item-title">购买份数</div>
                            <div>x {{data.goods_number}}</div>
                        </div>
                        <div class="goods-info-item" v-if="data.coin_num != 0">
                            <div class="item-title">K币抵扣</div>
                            <div>- {{data.coin_num}}</div>
                        </div>
                        <div class="goods-info-item">
                            <div class="item-title">支付金额</div>
                            <div>¥ {{data.order_price}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <van-empty  image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-if="!data.order_detail"/>

        <!-- 表单 -->
        <van-popup v-model="formShow" closeable round  position="bottom" >
            <div class="form">
                <div>提交预约信息</div>
                <van-form @submit="onSubmit" >
                    <van-field 
                        v-model="form.name" 
                        name="name" 
                        placeholder="请填写预约人姓名" 
                        label="姓名" 
                        :rules="[{ required: true, message: '请填写正确的名字',pattern: /^[\u4e00-\u9fa5]{2,5}$/ }]"
                    />
                    <van-field 
                        v-model="form.tel" 
                        name="tel" 
                        placeholder="请填写预约人手机号" 
                        label="手机号" 
                        :rules="[{ required: true, message: '请填写正确的手机号',pattern:/^1[0|1|3|4|5|6|7|8|9]\d{9}$/ }]"
                    />
                    <van-field 
                        v-model="form.remark" 
                        name="remark" 
                        placeholder="如果您有特殊要求，可备注" 
                        label="备注" 
                    />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">
                        提交
                        </van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>

        <!-- 升级套餐 -->
        <van-popup v-model="upgradeShow">
            <div class="upgrade">
                <div class="title">
                   限时优惠升级
                    <van-icon name="close" size="22" @click="shutPackSelect()"/>
                </div>
                <div class="info">
                    建议升级体检套餐，项目更全面，性价比更高
                </div>

                <van-radio-group v-model="packSelect">
                        <van-radio :name="item" v-for="item in data2.recommend_goods" :key="item.id">
                            <div class="radio-con">
                                <van-image width="38%" height="100%" :src="item.thumb_pic" />
                                <!-- <van-image width="40%" height="100%" src="https://img.yzcdn.cn/vant/cat.jpeg" /> -->
                                <div>
                                    <div class="radio-con-1 van-ellipsis">
                                        {{item.title}}
                                    </div>
                                    <div class="radio-con-2">
                                        限时+<strong>¥{{item.diff_price}}</strong>升级
                                        <van-icon name="info-o" style="margin-left:2px" @click="openPackInfo(item)"/>
                                    </div>
                                    <div class="radio-con-3 van-ellipsis" v-html="item.check_items">
                                        
                                    </div>
                                </div>

                            </div>
                        </van-radio>
                </van-radio-group>

                <div class="upgrade-button">
                    <van-button type="warning" round @click="giveUpgrade">放弃优惠</van-button>
                    <van-button type="info" round @click="confUpgrade(data.sn)">立即升级</van-button>
                </div>
            </div>
        </van-popup>

        <!-- 套餐说明 -->
        <van-popup v-model="packSelectInfo" closeable round  position="bottom">
            <div class="pack-info">
                <div class="title">
                   {{dataInfo.title}}
                </div>
                <div class="con" v-html="dataInfo.check_items"></div>

                <van-submit-bar  button-text="提交订单" @submit="confUpgrade(data.sn)" >
                    <template #default>
                        <div class="left">限时特惠：<strong style="color:red;font-size:15px">¥{{dataInfo.diff_price}}</strong> 可升级</div>
                    </template>
                </van-submit-bar>
            </div>
        </van-popup>

        <van-goods-action v-if="data.order_detail">
            <van-goods-action-icon icon="home-o" text="首页" @click="backHome"/>
            <van-goods-action-icon icon="chat-o" text="咨询客服" @click="kefuShow = true"/>   
            <van-goods-action-button type="warning" v-if="order_status == 2" @click="createOrder(data.sn)">去支付</van-goods-action-button>
            <van-goods-action-button  type="danger" text="我的订单" to="/my"/>
        </van-goods-action>

    
        <ServicePopup v-model="kefuShow" :kf_qrcode="data.get_goods.kf_info.kf_qrcode" />
        
    </div>
</template>

<script>
import { getFormatDate } from "@/utils/util";
import { weChatPay } from "@/wechat/weChatPay";
import ServicePopup from "@/components/ServicePopup";

export default {
    data(){
        return{
            form:{
                name:'',
                tel:'',
                remark:'',
                order_detail_id:'',
            },
           formShow:false,
           upgradeShow:false,
           packSelect:'', // 套餐选择
           packSelectInfo:false,//套餐说明，
           data:{
               get_goods:{
                   check_items:'',
                    kf_info:{
                        kf_qrcode:''
                    }
                },
            },
           data2:[], //升级套餐的数据
           dataInfo:{},//升级套餐的详情
           isCancelPay: false,
           kefuShow: false,
		   order_status:0  ,//商品状态
		   kindStatus: 0 //商品类型
        }
    },
    components: {
        ServicePopup
    },
    created(){
        axios.get(
                '/order_detail',
                {params:{order_sn:this.$route.query.order_sn}}
            ).then(res=>{
                console.log(res);
                // console.log(res.data)
                if(res.code == 201){
                    this.$router.push({path:'/home'})
                }
                else if(res.code != 1){
                    this.$toast(res.message)
                }
                else{
                    this.data = res.data
					this.order_status = res.data.order_code_status
					this.kindStatus = res.data.get_goods.type
                }
        })
    },
    methods:{
		// 一键复制
		copyText(text){
			const copyDom = document.createElement("div");
			copyDom.innerText = text;
			copyDom.style.position = "absolute";
			copyDom.style.top = "0px";
			copyDom.style.right = "0px";
			copyDom.style.zIndex = "-10";
			copyDom.style.opacity = "0";
			document.body.appendChild(copyDom);
			
			// 创建选中范围
			const range = document.createRange();
			range.selectNode(copyDom);
			// 移除剪切板中内容
			window.getSelection().removeAllRanges();
			// 添加新的内容到剪切板
			window.getSelection().addRange(range);
			document.execCommand("copy");
			copyDom.parentNode.removeChild(copyDom);
			this.$toast('复制成功')
		},
		createOrder(sn){
		    axios.post(
		        "continue_pay",
		        {order_sn:sn}
		    ).then(res => {
		        if(res.code == 1){
					this.createWxPay(res.data.payConfig)
		        }
		    })
		},
        //第三方并且已预约的时候，依然可以点击查看
        showThird(status,order_id){
           if((status == 1) && (this.data.third_id > 0)){
               console.log('第三方预约')
               console.log(order_id)
                axios.get(
                  "get_third_reserve",
                  {params:{order_detail_id:order_id}}
                ).then(res=>{
                    window.sessionStorage.setItem('thereCode',res.data.code)
                    window.sessionStorage.setItem('thereCodeHref',res.data.use_addr)
                    this.$router.push({path:'/app_code'})
                })

           }
        },
        hrefCoures(){
             window._czc.push(['_trackEvent','订单详情页', '查看课程'])
            window.sessionStorage.setItem("is_couese",'true')
            this.$router.push({path:'/course'})
        },
        backHome() {
            this.$router.push("/home")
        },
        updated() {
            this.$router.push({
                path: '/my/detail',
                query: {
                    order_sn: this.data.old_order_sn
                }
            })
           location.reload();
        },
        onSubmit(){
            axios.post(
                "/reserve_check",
                this.form
            ).then(res=>{
                if(res.code == 1){
                    this.formShow = false
                    this.form = {
                        name:'',
                        tel:'',
                        remark:'',
                        order_detail_id:'',
                    }
                    this.$store.commit("setOrderSuccess",res.data)
                    this.$router.push({path:`/my/code/success`})
                }
                else{
                    this.$toast(res.message)
                }  
            })
        },
        // 预约
        makeAppointment(value){
             window._czc.push(['_trackEvent','订单详情页', '点击预约'])
            if(this.data.third_id == 0){
                 this.formShow = true
                 this.form.order_detail_id = value.toString()
            }
            else{
                this.form.order_detail_id = value.toString()
                axios.get(
                    '/pay_done',
                    {params:{order_sn:this.data.sn}}
                ).then(res=>{
                    console.log(res)
                    if(res.code != 1){
                        this.$toast(res.message)
                    }
                    else{
                        // 有升级数据的时候
                        if(res.data.recommend_goods.length > 0){
                             this.upgradeShow = true
                             this.data2 = res.data
                            // console.log('xxx')
                        }
                        else{
                          axios.get(
                                "/third_reserve",
                                {params:{order_detail_id:this.form.order_detail_id}}
                            ).then(res=>{
                                console.log(res)
                                if(res.code == 1){
                                    window.sessionStorage.setItem('thereCode',res.data.third_code)
                                    window.sessionStorage.setItem('thereCodeHref',res.data.use_addr)
                                    this.$router.push({path:'/app_code'})
                                }
                                else{
                                     this.$router.push({path:'/wait'})
                                }
                            })
                        }
                    }
                })
            }
           
        },
        //放弃升级
        giveUpgrade(){
            this.$dialog.confirm({
                title:'确认放弃优惠吗？',
                message:'仅需补差<span style="font-size:15px;font-weight:700;color:red"> ¥'+this.data2.recommend_goods[0].diff_price+'</span>即可升级更多体验项目，开始预约后将不可升级',
                confirmButtonText:'确认放弃，立即预约',
                cancelButtonText:'我在想想'
            }).then(()=>{
                axios.get(
                    "/third_reserve",
                    {params:{order_detail_id:this.form.order_detail_id}}
                ).then(res=>{
                    console.log(res)
                    if(res.code == 1){
                        console.log(res.data)
                        window.sessionStorage.setItem('thereCode',res.data.third_code)
                        window.sessionStorage.setItem('thereCodeHref',res.data.use_addr)
                        this.$router.push({path:'/app_code'})
                    }
                    else{
                        this.$toast(res.message)
                         this.$router.push({path:'/wait',query:{kf:data.get_goods.kf_info.kf_qrcode}})
                    }
                })
            }).catch(()=>{
                console.log('想想')
            })
        },
        shutPackSelect(){
            this.upgradeShow = false
        },
        openPackInfo(data){
            this.dataInfo = data
            this.packSelectInfo = true
        },
		createWxPay(config){
			weChatPay({
			    wxPayConfig: config,
			    onSuccess: () => {
					let order_sn = this.$route.query.order_sn
					this.$router.push({
					    path: '/my/detail',
					    query: {
					        order_sn: order_sn,
							status:0
					    }
					})
			        location.reload();
			    },
			});
		},
        wxPay() {
            weChatPay({
                wxPayConfig: this.wxPayConfig,
                onSuccess: () => {
                    this.wxPaySuccess();
                },
                onCancel: () => {
                    this.wxPayCancel();
                }
            });
        },
        wxPaySuccess() {
            this.isCancelPay = false;
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '请稍候...',
            });
            setTimeout(() => {
                toast.type = "success";
                toast.message = "支付完成";
                setTimeout(() => {
                    toast.clear();
                    this.$router.push({
                        path: '/my/detail',
                        query: {
                            order_sn: this.updateOrderSn
                        }
                    })
                    location.reload();
                }, 1500);
            }, 700);
        },
        wxPayCancel() {
            this.isCancelPay = true;
            this.cancelPayGoodsId = this.packSelect.id;
        },
        // 确定升级
        async confUpgrade(sn){ 
            if (this.packSelect == '') {
                this.$toast('请选择套餐')
            } else{
                if (this.isCancelPay && this.packSelect.id === this.cancelPayGoodsId) {
                    this.wxPay();
                } else {
                    // 升级套装
                    const res = await axios.put("/order_update", {
                        order_sn: sn,
                        goods_id: this.packSelect.id
                    });
                    if (res.code == 1) {
                        this.updateOrderSn = res.data.orderSn;

                        if (res.data.payConfig) {
                            // 需要补差价
                            this.wxPayConfig = res.data.payConfig;
                            this.wxPay();

                        } else {
                            // 不需要支付，查看升级之后的订单详情
                            const toast = this.$toast.success({
                                duration: 0,
                                forbidClick: true,
                                message: "升级成功",
                            })
                            setTimeout(() => {
                                toast.clear();
                                this.$router.push({
                                    path: "/my/detail",
                                    query: {
                                        order_sn: this.updateOrderSn,
                                    }
                                })
                                location.reload();
                            }, 1500);
                        }
                    } else {
                        this.$toast(res.message)
                    }
                }
            }
        },
    },
    filters:{
        omit(value){
            let val = value.slice(0,-4) +'***'
            return val
        },
        time(value){
            var date = new Date(value * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
           
            return getFormatDate(date)
        }
    }
}
</script>

<style lang="less" scoped>
.van-empty {
    height: 100%;
    /deep/.van-empty__description {
        color: rgb(142,205,88);
        font-size: 15px;
        margin: 0;
    }
}
/deep/ .van-goods-action-icon{
    font-size: 12px !important;
}

// /deep/ .van-goods-action-button{
//     width: 25% !important;
//     // flex: 0 !important ;
//     // position: absolute;
//     // right: 0px;
// }

    .goods-box{
        background-color: white;
        padding: 8px 10px;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: 0px 2px 6px 0px rgba(200, 197, 197, 40);
        border: 1px solid rgba(255, 255, 255, 100);
        margin-bottom: 14px;
        /deep/ .van-cell{
            padding: 0px;
            .van-cell__title {
                font-weight: bold;
            }
        }
    }
    .goods-info{
        padding: 15px 5px;
        box-sizing: border-box;
        // background-color: rgba(249, 249, 249, 100);
        margin-bottom: 45px;
    }
    .goods-title{
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    .flex-item{
        margin-bottom: 15px;
        word-break:break-all;
        .small-title{
            // color: #c3b5b5;
            font-weight: bold;
            color: black;
            font-size: 14px;
            margin-bottom: 15px;
        }
    }
    .code-box{
        margin-bottom: 0px;
        border-top: 1px dashed rgba(232, 232, 232, 100);
        padding-top: 15px;
    }
    .code{
        display: flex;
        font-size: 13px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        /deep/ .van-button--mini{
            width: 68px;
        }
		.copy{
			font-size: 12px;
			text-align: right;
			// background-color: #F7F7F7;
			border: 1px solid #CCCCCC;
			color: #333;
			padding: 2px 12px;
			border-radius: 30px;
		}
    }
    .goods-info-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;
        .item-title{
            white-space: nowrap;
            margin-right: 15px;
            color: #666;
        }

    }
    // 表单
    .form{
        padding: 20px 20px;
        box-sizing: border-box;
        >div{
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
        }
    }
    /deep/ .van-radio{
        margin-bottom: 18px;
    }
    // 升级套餐
    .upgrade{
        width: 94vw;
        padding: 20px 20px;
        box-sizing: border-box;
        /deep/.van-radio__label{
            width: 100%;
            overflow: hidden;
        }
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .info{
            background: #e7e6e6;
            font-size: 13px;
            padding: 10px 8px;
            box-sizing: border-box;
            line-height: 23px;
            margin-bottom: 20px;
        }
        .radio-con{
            display: flex;
            height: 75px;
            justify-content: space-between;
            >div{
                width: 59%;
                font-size: 12px;
                // margin-left: 10px;
            }
            .radio-con-2{
                margin: 3px 0px;
                display: flex;
                font-size: 13px;
                align-items: center;
                white-space:nowrap;
                strong{
                    color: red;
                    margin: 0px 3px;
                }
            }
            .radio-con-3{
                white-space: nowrap;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }

        }
        .upgrade-button{
            display: flex;
            justify-content: space-evenly;
            margin-top: 30px;
            /deep/ .van-button--normal{
                padding: 0px 35px;
            }
        }
    }

    //套餐详情
    .pack-info{
        padding: 56px 20px;
        box-sizing: border-box;
        .title{
            font-weight: 700;
            color: black;
            margin-bottom: 25px;
            font-size: 15px;
        }
        .con{
            margin-bottom: 25px;
            font-size: 14px;
        }
        /deep/ .van-submit-bar__bar{
            justify-content: space-between;
        }
        .left{
            font-size: 13px;
            color: black;
        }
    }
    .kefu-popup {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        .kefu {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .van-image {
                width: 55%;
                margin-top: 15px;
            }
        }
        .kefu p {
            margin-bottom: 6px;
        }
    }
     
</style>