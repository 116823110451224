/**
 * 微信支付
 * 参数如下：
 * {
 *      wxPayConfig: {}     支付配置
 *      onSuccess: fun      支付成功回调
 *      onCancel: fun       支付取消回调
 *      onFail: fun         支付失败回调
 * }
 * @param {*} option 
 */
export function weChatPay(option) {
    if (typeof WeixinJSBridge == "undefined"){
        if(document.addEventListener){
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }   
    } else {
        onBridgeReady();
    }

    function onBridgeReady(){
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": option.wxPayConfig['appId'],    
            "timeStamp":option.wxPayConfig['timestamp'],        
            "nonceStr":option.wxPayConfig['nonceStr'],
            "package":option.wxPayConfig['package'],  
            "signType":option.wxPayConfig['signType'],         
            "paySign":option.wxPayConfig['paySign'], 
        }, (res) => {
            if(res.err_msg == "get_brand_wcpay_request:ok"){
                option.onSuccess && option.onSuccess();
            } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                option.onCancel && option.onCancel();
            } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                option.onFail && option.onFail();
            }
        }); 
    }
}

